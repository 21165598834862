import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Typography } from '@mui/material'
import GenericIcon from '../GenericIcon'
import { Modalities, modalityToGerman } from '../Modality'
import { supportEmail } from '../../login/utils.js'
import { filterLatestJobs, isStatusFinal } from '../../JobHelpers'
import { selectDataset, States, unselectDataset } from '../../../reducers/datasetsView'
import { Views } from '../../constants/Views'
import DatasetItem from './DatasetItem.js'
import AppDownloadLinks from './AppDownloadLinks.js'
import GroupItemsByUser from './GroupItemsByUser.js'
import { showDatasetOnMap } from './MapHelpers.js'
import { styled } from '@mui/material/styles'
import { selectMeasurementsById, addTagsToMeasurements, selectAllMeasurements } from '../../../reducers/measurements'
import { updateMeasurementTag } from '../../DataApi'
import { filterDatasets } from './DatasetUtils.js'
import { defaultErrorHandling } from '../../ErrorHandlingHelpers'
import { areTagKeysEqual } from '../../TagHelpers'
import { useSnackbarContext } from '../../SnackbarContext.js'
// TODO [RFR-301]: import DatasetTags from './DatasetTags'

// State selector, has no dependencies so it's defined outside the component
const selectMeasurementById = (state, measurementId) => selectMeasurementsById(state, measurementId)

/**
 * The data sets shown in the `Views.Datasets` of the sidebar, which currently displays
 * measurements.
 *
 * @author Armin Schnabel
 */
const Datasets = ({ map: initialMap, logout }) => {
  // Stateless Hooks
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbarContext()

  // Redux State
  const visibleLayerId = useSelector(state => state.ui.visibleLayerId) // view/direction/mode
  const view = useSelector(state => state.ui.view) // Changes when e.g. switching to "AnalysisView"
  const datasetsView = useSelector(state => state.datasetsView) // See reducer for states included
  const { active, modality, time, tags, expanded, selected } = datasetsView // Re-render makes sense
  const selectedMeasurementId = selected[selected.length - 1]
  const selectedDataset = useSelector(state => selectMeasurementById(state, selectedMeasurementId))
  const measurements = useSelector(selectAllMeasurements) // Which we render as DatasetItem
  const jobsById = useSelector((state) => state.jobs.entities)

  // Local state
  /**
   * We're defining 'map' as a state variable to manage it within this component. This way, we can
   * inject it into the 'showOnMap' function without causing an endless loop in the 'useEffect' that
   * has 'map' as a dependency.
   * Normally, if 'showOnMap' modifies 'map' and 'map' is a dependency for 'useEffect', it creates a
   * situation where the 'useEffect' endlessly triggers due to 'map' constantly changing. By using
   * 'useState', we can manage 'map' and control when and how it changes, thus avoiding this issue.
   */
  const [map] = useState(initialMap)
  // Contains UIDs of groups that are currently expanded
  const [expandedGroupUIDs, setExpandedGroupUIDs] = useState([])

  // Filters applied to datasets shown in list

  const modalityFilter = useCallback((d) => {
    return d.modality === modality || modality === Modalities.All
  }, [modality])

  const timeFilter = useCallback((d) => {
    const { from, to } = time
    if (from && to) {
      return d.startTimestamp < to && d.endTimestamp > from
    } else if (from) {
      return d.endTimestamp > from
    } else if (to) {
      return d.startTimestamp < to
    }
    return true
  }, [time])

  const tagFilter = useCallback((d) => {
    const activeTags = tags.activeTags
    if (activeTags.length === 0) return true // No active tags, no filtering needed

    return activeTags.every(tag => {
      const possibleValues = activeTags
        .filter(otherTag => areTagKeysEqual(otherTag, tag))
        .map(t => Object.values(t)[0])

      return d.tags !== undefined && possibleValues.includes(d.tags[Object.keys(tag)[0]])
    })
  }, [tags.activeTags])

  const getCombinedFilter = useCallback(() => {
    const tagFilterActive = tags.activeTags.length !== 0
    if (time.active && tagFilterActive) {
      return (d) => modalityFilter(d) && timeFilter(d) && tagFilter(d)
    } else if (tagFilterActive) {
      return (d) => modalityFilter(d) && tagFilter(d)
    } else if (time.active) {
      return (d) => modalityFilter(d) && timeFilter(d)
    } else {
      return (d) => modalityFilter(d)
    }
  }, [modalityFilter, tagFilter, tags.activeTags.length, time.active, timeFilter])

  const filteredMeasurements = useMemo(() => {
    return filterDatasets(measurements, getCombinedFilter())
  }, [measurements, getCombinedFilter])

  // Filters end

  const userItems = useMemo(() => {
    const userItemsInternal = {}

    filteredMeasurements.forEach(measurement => {
      const user = measurement.user
      if (userItemsInternal[user] === undefined) {
        userItemsInternal[user] = [measurement]
      } else {
        userItemsInternal[user].push(measurement)
      }
    })
    return userItemsInternal
  }, [filteredMeasurements])

  /**
   * Checks if a dataset is selectable.
   *
   * As this method is passed down to a child component, it's wrapped in `useCallback`.
   *
   * @param {*} dataset The dataset to check.
   * @param {*} informUser `true` if the user should be informed when the dataset is not selectable.
   * @returns `true` if the dataset is selectable.
   */
  const selectable = useCallback((dataset, informUser = true) => {
    if (active === States.SelectRemoveDatasets) {
      // We cannot move this to DatasetItem as the `selectable` function is used in this component
      const jobs = dataset.jobs.map(jobId => jobsById[jobId])
      const latestJobs = filterLatestJobs(jobs)
      const nonFinalJobs = latestJobs.filter(j => !isStatusFinal(j.status))
      if (nonFinalJobs.length > 0) {
        if (informUser) {
          enqueueSnackbar(
            'Messung besitzt noch laufende Aufträge und kann noch nicht gelöscht werden.'
          )
        }
        return false
      }
      return true // triggers `CustomCollectionItem.setChecked()`
    } else if (active === States.SelectAnalyzeDatasets) {
      const modality = dataset.modality
      const deserialized = dataset.deserialized
      // backend only suports TRANSFER_FILE_FORMAT_VERSION = 3
      const unsupportedVersion = dataset.formatVersion !== 3
      if (!deserialized || unsupportedVersion) {
        if (informUser) {
          const message = unsupportedVersion
            ? 'Messung wurde in einem Format aufgezeichnet das nicht unterstützt wird. Bei Fragen '
            : 'Messung ist noch nicht bereit. Falls der Zustand anhält, '
          enqueueSnackbar(message + 'bitte ' + supportEmail() + ' kontaktieren.')
        }
        return false
      }
      if (modality === Modalities.Car || modality === Modalities.Bicycle) {
        return true // triggers `CustomCollectionItem.setChecked()`
      } else {
        if (informUser) {
          enqueueSnackbar(modalityToGerman(modality) + ' wird noch nicht unterstützt')
        }
        return false
      }
    }
  }, [active, jobsById, enqueueSnackbar])

  /**
   * Unselects the specified dataset.
   *
   * @param {*} id The id of the item to hide.
   * @param unselect `True` if the items should be unselected (default behavior).
   */
  const hideItem = useCallback((map, selectedDataset, id, unselect = true) => {
    if (map.getLayer(id) && map.getSource(id)) {
      map.setLayoutProperty(id, 'visibility', 'none')
      map.removeLayer(id)
      map.removeSource(id)
    }
    // Unselect item not yet loaded into map
    // Reason: Another item was selected
    // before the previous one is loaded into map.
    // Unselect item if requested or not selectable (e.g. "on foot") [DAT-1459]
    if (unselect || !selectable(selectedDataset, false)) {
      dispatch(unselectDataset(id))
    }
  }, [dispatch, selectable])

  /**
   * Unselects all currently selected items.
   *
   * Wrapped in `useCallback`: function is a event handler and a dependency of `useEffect`.
   *
   * @param e the map click event or `null` if called manually
   * @param unselect `True` if the items should be unselected (default behavior).
   */
  const resetMap = useCallback((e, unselect = true) => {
    if (view === Views.Datasets && selected.length > 0) {
      selected.forEach(id => hideItem(map, selectedDataset, id, unselect))
    }
  }, [view, map, selectedDataset, selected, hideItem])

  useEffect(() => {
    map.on('click', resetMap)

    // Return a cleanup function to be run when the effect is run again
    // or when the component unmounts
    return () => {
      map.off('click', resetMap)
    }
  }, [resetMap, map]) // only register this handler once

  /**
   * Is triggered when this component is re-rendered.
   */
  useEffect(() => {
    if (
      // Hide tracks from map when the analysis selection is active
      active === States.ChooseAnalyzeMode ||
      active === States.SelectAnalyzeDatasets ||
      active === States.SelectRemoveDatasets ||
      active === States.AddRemoveTagsFromMeasurements
    ) {
      resetMap(null, false) // clears map but does not unselect the items
    }
  }, [active, resetMap])

  /**
   * TODO
   *
   * As this method is passed down to a child component, it's wrapped in `useCallback`.
   */
  const addTagsToMeasurementsCallBack = useCallback(async (item, tag) => {
    dispatch(addTagsToMeasurements([item.id], tag))

    await updateMeasurementTag(dispatch, defaultErrorHandling, logout, item, tag)
  }, [dispatch, logout])

  /**
   * Wrapped with useCallbacks as this is a dependeny of another function which uses useCallback.
   */
  const showDatasetWithDashedLines = useCallback(async (dataset) => {
    const currentId = dataset.id.toString()
    const lineStringGeometry = dataset.geometry
    if (lineStringGeometry.coordinates.length === 0) {
      enqueueSnackbar('Messung enthält keine Geodaten!')
      dispatch(unselectDataset(dataset.id))
      return
    }

    if (!map.getSource(currentId)) {
      map.addSource(currentId, {
        type: 'geojson',
        data: lineStringGeometry
      })
    }
    if (!map.getLayer(currentId)) {
      map.addLayer({
        id: currentId,
        type: 'line',
        source: currentId,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-width': 3,
          'line-color': '#3F8730',
          'line-dasharray': [2, 2]
        }
      })
    }

    map.setLayoutProperty(currentId, 'visibility', 'visible')
    map.fitBounds(lineStringGeometry.coordinates,
      { padding: { top: 25, bottom: 25, left: 25, right: 25 }, maxZoom: 17 })
  }, [map, dispatch, enqueueSnackbar])

  const showOnMap = useCallback(
    (dataset, logout, map) => {
      showDatasetOnMap(dataset, logout, map, dispatch, visibleLayerId, showDatasetWithDashedLines)
    },
    [visibleLayerId, dispatch, showDatasetWithDashedLines]
  )

  useEffect(() => {
    if (selected.length > 0 && selectedDataset && active === States.ShowDatasets) {
      showOnMap(selectedDataset, logout, map, dispatch, visibleLayerId, showDatasetWithDashedLines)
    }
  }, [
    selected,
    map,
    showOnMap,
    logout,
    selectedDataset,
    active,
    dispatch,
    showDatasetWithDashedLines,
    visibleLayerId
  ])

  /**
   * Selects the clicked dataset.
   *
   * As this method is passed down to a child component, it's wrapped in `useCallback`.
   *
   * @param {*} measurement The item to be selected.
   */
  const onDatasetClicked = useCallback((e, measurement) => {
    e.preventDefault()
    if (active !== States.AddRemoveTagsFromMeasurements) {
      if (selected.some(id => id === measurement.id)) {
        return
      }

      // Unselect items which are still active
      if (selected.length > 0) {
        selected.forEach(id => hideItem(map, selectedDataset, id))
      }

      // Select and render the dataset
      dispatch(selectDataset(measurement.id))
      // showOnMap(measurement) is called via useEffect as `selected` is updated asynchronously
    }
  }, [map, active, selected, dispatch, selectedDataset, hideItem])

  /**
   * Returns a list of JSX elements representing the datasets.
   *
   * @param {*} items The items to render.
   * @returns The list of JSX elements.
   */
  const list = useMemo(() => (items) => {
    // Return one JSX element per dataset
    return items.map(dataset => {
      const isSelected = selected.some(id => id === dataset.id)
      return (
        <DatasetItem
          key={dataset.id}
          dataset={dataset}
          selectable={selectable}
          isSelected={isSelected}
          onDatasetClicked={onDatasetClicked}
          addTagsToMeasurementsCallBack={addTagsToMeasurementsCallBack}
          active={active}
        />
      )
    })
  }, [selected, selectable, active, onDatasetClicked, addTagsToMeasurementsCallBack])

  const render = () => {
    const userCount = Object.keys(userItems).length
    if (userCount === 0) {
      return (
        <Container>
          <Typography align="center">Keine Datensätze gefunden.</Typography>
          {AppDownloadLinks()}
        </Container>
      )
    }

    const multiUser = userCount > 1
    return active !== States.AddRemoveAvailableTags &&
      active !== States.RemoveAvailableTags &&
      active !== States.AddAvailableTags
      ? <Container>
          Messungen {multiUser ? '(Gruppiert nach Nutzer)' : ''}
          <GenericIcon
            position="static"
            color="#c1c1c1"
            icon="help_outline"
            float='right'
            transform="scale(0.8)"
            tooltip={'Hier werden alle übertragenen Messungen aufgelistet und deren ' +
              'Verarbeitungsstatus angezeigt.'}
          />
          <Box>
            <GroupItemsByUser
              userItems={userItems}
              multiUser={multiUser}
              expandedGroupUIDs={expandedGroupUIDs}
              setExpandedGroupUIDs={setExpandedGroupUIDs}
              list={list}
              expanded={expanded}
            />
            {filteredMeasurements.length === 0 ? AppDownloadLinks() : null}
          </Box>
        </Container>
      : (
          ''
        )
  }

  return render()
}

const Container = styled(Box)({
  marginTop: '10px'
})

Datasets.propTypes = {
  map: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
}

export default Datasets
