import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { styled } from '@mui/material/styles'
import { List, ListItemButton, Collapse, Badge as MUIBadge } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { nbsp, todayIcon, groupNameTranslater, groupItemsByDate } from './DatasetsGroupingHelpers.js'
import { addExpanded, removeExpanded } from '../../../reducers/datasetsView.js'

const GroupItemsByUser = ({
  userItems,
  expandedGroupUIDs,
  setExpandedGroupUIDs,
  list,
  multiUser,
  expanded
}) => {
  // Stateless Hooks
  const dispatch = useDispatch()

  const handleRemoveExpanded = useCallback((id) => {
    dispatch(removeExpanded(id))
  }, [dispatch])

  const handleToggleExpand = useCallback((user) => {
    expanded.includes(user) ? handleRemoveExpanded(user) : dispatch(addExpanded(user))
  }, [expanded, handleRemoveExpanded, dispatch])

  /**
   * Render the user name as a header if more than one user is present.
   *
   * @param {*} user The user name.
   * @param {*} expanded True if the user is expanded.
   * @param {*} itemCount The number of items for the user.
   * @returns The user name as a header or null if only one user is present.
   */
  const userNameHeader = (user, expanded, itemCount) => {
    return (
      <ListItemButton
        onClick={() => handleToggleExpand(user)}
        sx={{
          marginTop: '3px',
          border: '1px solid rgb(235, 235, 235)',
          backgroundColor: 'rgb(247, 247, 247)', // Light gray background
          color: 'rgb(70, 70, 70)', // Text color
          display: 'flex', // Use flexbox for layout
          justifyContent: 'space-between', // Space between username and badge
          alignItems: 'center', // Center vertically
          '&:hover': {
            backgroundColor: 'rgb(240, 240, 240)' // Slightly darker gray on hover
          }
        }}
      >
        <Header>{user}</Header>
        {nbsp}
        {expanded.includes(user) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <Badge
          badgeContent={itemCount}
          sx={{ ml: 'auto', mr: '5px', color: 'grey' }}
        />
      </ListItemButton>
    )
  }

  const users = Object.keys(userItems)

  return users.map((user) => {
    // Group by date or other logic
    const groupedItems = groupItemsByDate(userItems[user])
    const itemCount = Object.values(groupedItems).reduce((acc, items) => acc + items.length, 0)

    // Define the custom order of groups (ensure e.g. "this year" is before "2023")
    const orderedGroups = ['today', 'lastWeek', 'thisMonth', 'thisYear',
      ...Object.keys(groupedItems)
        .filter(key => !['today', 'lastWeek', 'thisMonth', 'thisYear'].includes(key))
        .sort((a, b) => b - a)
    ]

    return (
      <div key={user}>
        {multiUser && userNameHeader(user, expanded, itemCount)}

        {/* Show the user's groups with items */}
        {
          (!multiUser || expanded.includes(user)) &&
          orderedGroups.map((groupKey) => {
            const itemsInAGroup = groupedItems[groupKey]
            if (!itemsInAGroup || itemsInAGroup.length === 0) { // Skip empty groups
              return null
            }

            const groupUID = `${user}-${groupKey}`
            const isExpanded = expandedGroupUIDs.includes(groupUID)

            return (
              /* Assigning a unique, static key to the child components to avoid
              unnecessary re-rendering [RFR-137] */
              <div key={groupUID}>
                <ListItemButton
                  onClick={() =>
                    setExpandedGroupUIDs((prev) =>
                      prev.includes(groupUID)
                        ? prev.filter((id) => id !== groupUID)
                        : [...prev, groupUID]
                    )
                  }
                  sx={{
                    backgroundColor: 'rgb(247, 247, 247)', // Light gray background
                    color: 'rgb(70, 70, 70)', // Text color
                    display: 'flex', // Use flexbox for layout
                    justifyContent: 'space-between', // Space between group name and badge
                    alignItems: 'center', // Center vertically
                    '&:hover': {
                      backgroundColor: 'rgb(240, 240, 240)' // Slightly darker gray on hover
                    }
                  }}
                >
                  <SubHeader>
                    {todayIcon(multiUser)}
                    {nbsp.repeat(5)}
                    {groupNameTranslater[groupKey] || groupKey}
                  </SubHeader>
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  <Badge
                    badgeContent={itemsInAGroup.length}
                    sx={{ ml: 'auto', mr: '5px', color: 'grey' }}
                  />
                </ListItemButton>

                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                  <List disablePadding>{list(itemsInAGroup)}</List>
                </Collapse>
              </div>
            )
          })}
      </div>
    )
  })
}

GroupItemsByUser.propTypes = {
  userItems: PropTypes.object.isRequired,
  expandedGroupUIDs: PropTypes.array.isRequired,
  setExpandedGroupUIDs: PropTypes.func.isRequired,
  list: PropTypes.func.isRequired,
  expanded: PropTypes.array.isRequired,
  multiUser: PropTypes.bool.isRequired
}

const Header = styled('span')({
  fontSize: '14.5px',
  fontWeight: 'bold',
  color: 'rgb(70,70,70)'
})

const SubHeader = styled('span')({
  fontSize: '14.5px',
  color: 'rgb(70,70,70)',
  display: 'flex', // Ensure that icons and text are centered
  alignItems: 'center' // Vertically center the icon and text
})

const Badge = styled(MUIBadge)({
  float: 'right',
  fontSize: '1rem'
})

export default GroupItemsByUser
