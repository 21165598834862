/**
 * The possible data types.
 */
export const Types = {
  Locations: 'LOCATIONS',
  Heatmap: 'HEATMAP',
  Segments: 'SEGMENTS'
}

/**
 * The possible export formats, depending on the data type.
 */
export const Formats = {
  // only available for Types.Locations
  Csv: 'CSV',
  Json: 'JSON',
  // only available for Types.Segments and Types.Heatmap
  Shapefile: 'SHAPEFILE'
}
