import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateExportView } from '../../../reducers/exportView'
import { Formats, Types } from '../../constants/ExportConstants'
import { getLocalStorage, getApiUrl, setLocalStorage, supportEmail, asyncRefreshTokenIfRequired } from '../../login/utils.js'
import { Views } from '../../constants/Views'
import ControlButton from '../../ControlButton'
import ExportFilter from './ExportFilter'
import { LocalStorage } from '../../constants/LocalStorage'
import { useSnackbarContext } from '../../SnackbarContext.js'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Colors } from '../../constants/Colors.js'

const STATUS_SUBMITTING = 'SUBMITTING'

/**
 * Control elements shown in the `Views.Export` mode where the user can export data.
 *
 * @author Armin Schnabel
 */
const ExportView = ({ viewControls }) => {
  // Stateless hooks
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbarContext()

  // Local state
  const [status, setStatus] = useState(null)

  // Redux state
  const exportView = useSelector((state) => state.exportView)
  const view = useSelector((state) => state.ui.view)

  const submitting = status === STATUS_SUBMITTING

  const selectType = useCallback((type) => {
    if (exportView.type === type) return
    if (type === '' ||
        (type !== Types.Locations && type !== Types.Segments && type !== Types.Heatmap)) {
      throw new Error('Unknown type: ' + type)
    }

    dispatch(
      updateExportView({ type, format: type === Types.Locations ? Formats.Csv : Formats.Shapefile })
    )
  }, [dispatch, exportView.type])

  const selectFormat = useCallback((format) => {
    if (exportView.format === format) return
    if (![Formats.Csv, Formats.Json, Formats.Shapefile].includes(format)) {
      throw new Error('Unknown format: ' + format)
    }

    dispatch(updateExportView({ format }))
  }, [dispatch, exportView.format])

  const handleRequestError = useCallback((status) => {
    switch (status) {
      case 401:
        enqueueSnackbar('Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')
        localStorage.clear()
        setLocalStorage(LocalStorage.TermsAccepted, true)
        navigate('/')
        break
      case 403:
        enqueueSnackbar('Fehlende Berechtigung.')
        break
      default:
        enqueueSnackbar(`Fehler: ${status}. Bitte kontaktieren Sie ${supportEmail()}`)
    }
  }, [enqueueSnackbar, navigate])

  const downloadCSV = useCallback(async (endpoint, format, startTime, endTime) => {
    await asyncRefreshTokenIfRequired()
    const accessToken = getLocalStorage(LocalStorage.AccessToken)
    const url = `${getApiUrl()}export/${endpoint}`

    const req = new XMLHttpRequest()
    req.open('GET', url, true)
    req.responseType = 'blob'
    req.setRequestHeader('Authorization', 'Bearer ' + accessToken)
    req.setRequestHeader('format', format)
    if (startTime) req.setRequestHeader('start-time', localISOString(startTime))
    if (endTime) req.setRequestHeader('end-time', localISOString(endTime))

    // From https://stackoverflow.com/a/49674385/5815054
    req.onload = function (event) {
      if (req.status !== 200) {
        handleRequestError(req.status)
        setStatus(null)
        return
      }

      let blob

      // If response is base64-encoded (mock API case)
      if (typeof req.response === 'string') {
        // Decode base64 into binary string
        const binaryString = atob(req.response)
        const binaryData = new Uint8Array(binaryString.length)

        for (let i = 0; i < binaryString.length; i++) {
          binaryData[i] = binaryString.charCodeAt(i)
        }

        // Create a Blob from the binary data
        blob = new Blob([binaryData], { type: req.getResponseHeader('content-type') })
      } else {
        // For real API, response is already a Blob
        blob = req.response
      }

      const contentDisposition = req.getResponseHeader('content-disposition')
      // IE/EDGE seems not returning some response header
      const fileName = contentDisposition
        ? contentDisposition.substring(contentDisposition.indexOf('=') + 1)
        : `unnamed.${req.getResponseHeader('content-type').split('/')[1]}`

      const el = document.getElementById('hidden_export_link')
      el.href = window.URL.createObjectURL(blob)
      el.download = fileName
      el.click()

      setStatus(null)
    }// .bind(this) /* bind injects the context `this` into the function */

    req.send()

    /*
      Old code from before migrating to functional component / React 18:

    // This method does not work for the shapefile export, only a corrupt file is downloaded
    const headers = {
      Authorization: 'Bearer ' + token, // TODO: use apiSlice to inject fresh token
      format
    }
    if (startTime.length > 0) {
      headers['start-time'] = startTime
    }
    if (endTime.length > 0) {
      headers['end-time'] = endTime
    }
    axios.get(url, { headers })
      .then(res => {
        const blob = new Blob([res.data], { type: 'text/csv' })
        const link = document.createElement('a')
        link.style = 'display: none'
        document.body.appendChild(link)
        const url = window.URL.createObjectURL(blob)
        link.href = url

        let fileName = null
        const contentType = res.headers['content-type']
        // IE/EDGE seems not returning some response header
        if (res.headers['content-disposition']) {
          const contentDisposition = res.headers['content-disposition']
          fileName = contentDisposition.substring(contentDisposition.indexOf('=') + 1)
        } else {
          fileName = 'unnamed.' + contentType.substring(contentType.indexOf('/') + 1)
        }
        link.download = fileName

        link.click()
        window.URL.revokeObjectURL(url)
        this.setState({ status: null })
      })
      .catch(err => {
        const errorCode = err.response.status
        switch (errorCode) {
          case 401:
            enqueueSnackbar('Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.')

            // Logout
            localStorage.clear()
            setLocalStorage(LocalStorage.TermsAccepted, true) // Avoid having to re-accept terms
            this.props.navigate('/')
            break
          case 403:
            enqueueSnackbar('Fehlende Berechtigung.')
            break
          default:
            enqueueSnackbar('Fehler: ' + errorCode + '. Bitte kontaktieren Sie den Support.')
        }
        this.setState({ status: null })
        return null
      }) */

    // return this.getCSV
  }, [handleRequestError])

  const submit = useCallback(async (e) => {
    e.preventDefault()
    setStatus(STATUS_SUBMITTING)

    const { type, format, time: { from: startTime, to: endTime } } = exportView
    const endpoint =
      type === Types.Locations
        ? 'locations'
        : type === Types.Segments ? 'segments/shapefile' : 'points/shapefile'
    await downloadCSV(endpoint, format.toLowerCase(), startTime, endTime)
  }, [exportView, downloadCSV])

  const localISOString = (timestamp) => {
    const offsetMillis = new Date().getTimezoneOffset() * 60_000
    return new Date(timestamp - offsetMillis).toISOString()
  }

  return (
    <Container $view={view}>
      <Actions>
        {viewControls}

        <label>Filter</label>
        <ExportFilter />

        <div>
          <label>Daten</label><br />
          <ControlButton
            text="Tracks"
            onClick={() => selectType(Types.Locations)}
            active={true}
            backgroundColor={exportView.type === Types.Locations ? Colors.Primary : Colors.Neutral}
          />
          <ControlButton
            text="Heatmap"
            onClick={() => selectType(Types.Heatmap)}
            active={true}
            backgroundColor={exportView.type === Types.Heatmap ? Colors.Primary : Colors.Neutral}
          />
          <ControlButton
            text="Segmente"
            onClick={() => selectType(Types.Segments)}
            active={true}
            backgroundColor={exportView.type === Types.Segments ? Colors.Primary : Colors.Neutral}
          />
        </div>

        <div>
          <label>Ausgabeformat</label><br />
          {exportView.type === Types.Locations && (
            <>
              <ControlButton
                text="CSV"
                onClick={() => selectFormat(Formats.Csv)}
                active={true}
                backgroundColor={
                  exportView.format === Formats.Csv ? Colors.Primary : Colors.Neutral
                }
              />
              <ControlButton
                text="JSON"
                onClick={() => selectFormat(Formats.Json)}
                active={true}
                backgroundColor={
                  exportView.format === Formats.Json ? Colors.Primary : Colors.Neutral
                }
              />
            </>
          )}
          {(exportView.type === Types.Segments || exportView.type === Types.Heatmap) && (
            <ControlButton
              text="Shapefile"
              onClick={() => selectFormat(Formats.Shapefile)}
              active={exportView.format === Formats.Shapefile}
            />
          )}
        </div>

        <label>Aktionen</label><br />
        <ControlButton
          onClick={submit}
          text={submitting ? 'Exportiere Daten ...' : 'exportieren'}
          disabled={submitting}
        />

        <a id="hidden_export_link" style={{ display: 'none' }} />
      </Actions>
    </Container>
  )
}

ExportView.propTypes = {
  viewControls: PropTypes.node.isRequired
}

const Container = styled('div')(({ $view }) => ({
  display: $view === Views.Export ? 'block' : 'none',
  height: '100%',
  overflowY: 'auto'
}))

const Actions = styled(Box)({
  padding: '0px 10px 10px 10px'
})

export default ExportView
