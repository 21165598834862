/**
 * The supported keys of data which is stored by the app in the local storage.
 */
export const LocalStorage = {
  ErrorTrackingAccepted: 'errorTrackingAccepted',
  TermsAccepted: 'termsAccepted',
  RefreshToken: 'refreshToken',
  AccessToken: 'accessToken',
  ExpiresAt: 'expiresAt',
  Username: 'username',
  UserId: 'userId',
  IdToken: 'idToken',
  AuthServiceConfiguration: 'authServiceConfiguration'
}

/**
 * Helps to ensure the local storage is not overwritten when multiple instances of our
 * app run under the same domain.
 */
export const localStorageSuffix = ''
